export default {
  data() {
    return {
      loading: false,
      loadingView: false,
      TableLoading: false,

      //Filter Menu
      start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    start_menu: false,
    end_menu: false,
    //Add Lost Bottle
      lost_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      loadingCustomer: false,
      lost_menu: false,
      listlostbottles: [],
      barcodelostes: [],
      barcodelost: "",
      count: 0,
      offset: 15,
      pagination: {},
      per_page: 12,
      search: "",
      sumTotalBottle: 0,
       sumTotalValue: 0,

      addLostBottle: {},
      editLostBottle:{},
      selectedLostPlace: "factory",
      selectedLostPlaceFilter:'all',
      factoryUser: [],
      selectedFactory: "",
      selectedOffender: "",

      selectedTruck: "",
      trucks: [],
      deliveryUser: [],
      type: "driver",

      //Customer List
      ຈຸດສັງເກດ: [],
      customers: [],
      selectedຈຸດສັງເກດ: "",
  

      branches: [],
      selectedBranch: "",
      branchUsers: [],

      barcodes:[],
      barcode:'',
      lostplaces: [
        {
          id: 1,
          value: "factory",
          name: "ໂຮງງານ",
        },
        {
          id: 2,
          value: "driver",
          name: "ລົດຂົນສົ່ງ",
        },
        {
          id: 3,
          value: "branch",
          name: "ສາຂາ",
        },
        {
          id: 4,
          value: "customer",
          name: "ລູກຄ້າ",
        },
      ],
      ////////
      server_errors: {
        barcode:"",
      },
      serverMsg:'',
      headers: [
        {
          text: "ຜູ້ຮັບຜິດຊອບ",
          align: "start",
          value: "offender",
        },
        { text: "ສະຖານທີເສຍຫາຍ", value: "lost_place" },
        { text: "ຈຳນວນຕຸກ", value: "amountBottle" },
        // { text: "Email", value: "email" },
        // { text: "ຈຸດສັງເກດທີ່ຢູ່", value: "ຈຸດສັງເກດ" },
        { text: "ຄ່າເສຍຫາຍ", value: "pay_for_fine" },
        { text: "ໝາຍເຫດ", value: "remark" },
        // { text: "Created By", value: "created_by" },

        { text: "ວັນທີເສຍ", value: "lost_date" },
        { text: "ວັນທີອັບເດດ", value: "createdDate" },
        { text: "Actions", value: "actions", sortable: false,align: "center", },
      ],
      userRule: [(v) => !!v || "User is required"],
      lostPlaceRule: [(v) => !!v || "ກາລຸນາປ້ອນບ່ອນເສຍຫາຍ"],
      ruleDistrict: [(v) => !!v || "District is required"],
      ruleBranch: [(v) => !!v || "Branch is required"],
      ruleTruck: [(v) => !!v || "Truck is required"],
      ruleCustomer: [(v) => !!v || "Customer is required"],
      payforfineRules: [(v) => !!v || "ການລຸນາປ້ອນຄ່າເສຍຫາຍ"],
      toast: {
        value: true,
        color: "success",
        msg: "Success",
      },
      toast_error: {
        value: true,
        color: "error",
        msg: "ເກີດຂໍ້ຜິດພາດບາງຢ່າງ",
      },
    };
  },
  methods: {
    OpenModalAdd() {
      this.$store.commit("modalAdd_State", true);
      this.fetchFactoryUser();
      this.fetchDriver();
      this.listDeliveryUser();
      this.listBranch();
      this.listBranchUser();
      this.fetchຈຸດສັງເກດ();
    },
    AddItem() {
      if (this.$refs.form.validate() == true) {
        this.SubmitItem();
      }
    },
    SubmitItem() {
      this.addLostBottle.offender_id = this.selectedOffender;
      this.addLostBottle.lost_date = this.lost_date;
      this.addLostBottle.lost_place = this.selectedLostPlace;
      this.addLostBottle.barcode = this.barcodes;
      
      this.loading = true;

      this.$admin
        .post("add/lost/bottles", this.addLostBottle)
        .then((res) => {
          if (res.data.success == true) {
            setTimeout(() => {
              this.loading = false;
              this.CloseModalAdd();
              this.addLostBottle = {};
              this.fetchLostBottle();
              this.reset();
              this.$store.commit("Toast_State", this.toast);
            }, 300);
          }
        })
        .catch((error) => {
          this.loading = false;
          // this.$store.commit("Toast_State", this.toast_error);
          if (error.response.status == 422) {
            var obj = error.response.data.errors;
            var msg = error.response.data.msg;
            this.serverMsg = msg;
            this.$store.commit("Toast_State", this.toast_error);
            for (let [key, addLostBottle] of Object.entries(obj)) {
              this.server_errors[key] = addLostBottle[0];
            }
          }
        });
    },

    CloseModalAdd() {
      this.customer = {};
      this.reset();
      this.$store.commit("modalAdd_State", false);
    },

    AddBarcode() {
      if(this.barcodes.length > 0){
        this.barcodes.filter(item =>{
          if(item !== this.barcode){
            this.barcodes.push(this.barcode);
          }
        }) 
      } else{
        this.barcodes.push(this.barcode);
      }
      this.barcode = "";

    },
    RemoveBarcode(index) {
      this.barcodes.splice(index,1);

    },

    OpenModalEdit(item) {
      (item);
      this.editLostBottle = item;
      this.$store.commit("modalEdit_State", true);
    },

    UpdateItem() {
      if (this.$refs.form.validate() == true) {
        this.loading = true;
        this.$admin
          .post("approved/lost/bottle/" + this.editLostBottle.id, this.editLostBottle)
          .then((res) => {
            if (res.data.success == true) {
              setTimeout(() => {
                this.loading = false;
                this.CloseModalEdit();
                this.editLostBottle = {};
                this.reset();
                this.$store.commit("Toast_State", this.toast);
              }, 300);
            }
          })
          .catch((error) => {
            this.loading = false;
            this.$store.commit("Toast_State", this.toast_error);
            if (error.response.status == 422) {
              var obj = error.response.data.errors;
              var msg = error.response.data.msg;
              this.serverMsg = msg;
              this.$store.commit("Toast_State", this.toast_error);
              for (let [key, addLostBottle] of Object.entries(obj)) {
                this.server_errors[key] = addLostBottle[0];
              }
            }
          });
      }
    },

    CloseModalEdit() {
      this.editLostBottle = {};
      this.reset();
      this.$store.commit("modalEdit_State", false);
    },

    FilterVillages(districtId) {
      const result_checking = this.filterVillages.filter((item) => {
        return item.district_id == districtId;
      });
      this.listVillages = result_checking;
      this.selectedVillage = { ...this.listVillages[0] };
    },

    closeDelete() {
      this.$store.commit("modalDelete_State", false);
    },

    deleteItem(id) {
      this.customerID = id;
      this.$store.commit("modalDelete_State", true);
    },

    deleteItemConfirm() {
      this.loading = true;
      this.$admin
        .delete("delete-customer/" + this.customerID)
        .then((res) => {
          if (res.data.success == true) {
            setTimeout(() => {
              this.$store.commit("modalDelete_State", false);
              this.loading = false;
              this.$store.commit("Toast_State", this.toast);
            }, 300);
          }
        })
        .catch(() => {
          this.$store.commit("Toast_State", this.toast_error);
          this.$store.commit("modalDelete_State", false);
          this.loading = false;
        });
    },
    reset() {
      this.$refs.form.reset();
    },

    RefreshVillage() {
      this.$admin
        .get("list-ຈຸດສັງເກດ")
        .then((res) => {
          this.filterVillages = res.data.listVillages;
          this.TableLoading = false;
          this.FilterVillages(this.selectedDistrict);
        })
        .catch(() => {
          this.TableLoading = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },

    OpenAddBarcode() {
      this.$store.commit("modalAdd2_State", true);
    },

    CloseAddVillage() {
      this.$store.commit("modalAdd2_State", false);
    },
    viewInvoice(value) {
      this.loadingView = true;
      this.customerDetail = value;
      this.$store.commit("modalView_State", true);
    },
    closeModalView() {
      this.loadingView = false;
      this.$store.commit("modalView_State", false);
    },
    // Fetch Data
    fetchLostBottle() {
      this.TableLoading = true;
      this.$admin
        .get("list/lost/bottles", {
          params: {
            lost_place: this.selectedLostPlaceFilter,
            startDate: this.start_date,
            endDate: this.end_date,
            page: this.pagination.current_page,
            per_page: this.per_page,
            search: this.search,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.listlostbottles = res.data.listBottles.data;
            this.count = res.data.listBottles.total;
            this.sumTotalBottle = res.data.sumTotalBottle;
            this.sumTotalValue = res.data.sumTotalPayForFine;
            this.pagination = res.data.listBottles;
            this.TableLoading = false;
            this.start_menu = false;
            this.end_menu = false;
          }

        })
        .catch(() => {
          this.TableLoading = false;
          this.start_menu = false;
          this.end_menu = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },

    fetchFactoryUser() {
      this.$admin
        .get("list-factory-user")
        .then((res) => {
          setTimeout(() => {
            this.factoryUser = res.data.factoryUsers;
          }, 100);
        })
        .catch(() => {});
    },
    fetchDriver() {
      this.$admin
        .get("list-water-truck")
        .then((res) => {
          setTimeout(() => {
            this.trucks = res.data.trucks;
            this.listDeliveryUser();
          }, 100);
        })
        .catch(() => {});
    },
    listDeliveryUser() {
      this.$axios
        .get("filter-users", {
          params: {
            type: this.type,
            truckId: this.selectedTruck,
          },
        })
        .then((res) => {
          setTimeout(() => {
            this.deliveryUser = res.data.users;
          }, 100);
        })
        .catch(() => {});
    },
    listBranch() {
      this.$axios
        .get("list-branches")
        .then((res) => {
          setTimeout(() => {
            this.branches = res.data.listBranches;
            this.listBranchUser();
          }, 100);
        })
        .catch(() => {});
    },
    listBranchUser() {
      this.$axios
        .get("filter-users", {
          params: {
            type: "branch",
            branchId: this.selectedBranch,
          },
        })
        .then((res) => {
          setTimeout(() => {
            this.branchUsers = res.data.users;
          }, 100);
        })
        .catch(() => {});
    },
    fetchຈຸດສັງເກດ() {
      this.$axios
        .get("list-ຈຸດສັງເກດes")
        .then((res) => {
          setTimeout(() => {
            this.ຈຸດສັງເກດ = res.data.districts;
            this.listCustomer();
          }, 100);
        })
        .catch(() => {});
    },
    listCustomer() {
      if(!this.selectedຈຸດສັງເກດ == ''){
        this.loadingCustomer = true;
        this.$axios
          .get("filter-customers/" + this.selectedຈຸດສັງເກດ)
          .then((res) => {
            setTimeout(() => {
              this.customers = res.data.customers;
              this.loadingCustomer = false;
            }, 100);
          })
          .catch(() => {
            this.loadingCustomer = false;
          });
      }
      },
      getstLostPlace(value){
        if(value == 'factory') return 'ໂຮງງານ'
        else if(value == 'truck') return 'ລົດຂົນສົ່ງ'
        else if(value == 'branch') return 'ສາຂາ'
        else if (value == 'customer') return 'ລູກຄ້າ'
        else return;

      }
  },

  watch: {
    selectedFactoryUser: function () {
      this.fetchFactoryUser();
    },
    selectedTruck: function () {
      this.listDeliveryUser();
    },
    selectedBranch: function () {
      this.listBranchUser();
    },
    selectedຈຸດສັງເກດ: function () {
      this.listCustomer();
    },
    // 'borcode': function () {
    //   this.server_errors.barcode = '';
    // },
  },

  created() {
    // this.fetchLostBottle();
  },
};
