<template>
  <div>
    <v-card>
      <v-tabs v-model="tab">
        <v-tab href="#tab-1">ທຳລາຍຕຸກນ້ຳ</v-tab>
        <!-- <v-tab href="#tab-2">ລາຍການຕຸກນ້ຳທີ່ທຳລາຍ</v-tab> -->
      </v-tabs>
      <!-- <hr /> -->

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-card flat>
            <v-card-text>
              <AddBottleBroken />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <!--
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-2">
          <v-card flat>
            <v-card-text>
              <ListBottleBroken />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      -->
      <!--
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="i in 3" :key="i" :value="'tab-' + i">
          <v-card flat>
            <v-card-text>{{ text }}</v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      -->
    </v-card>
  </div>
</template>

<script>
import AddBottleBroken from "@views/BottleBroken/add_bottle_broken_tab";
// import ListBottleBroken from "@views/BottleBroken/list_bottle_broken_tab";
export default {
  components: {
    AddBottleBroken,
    // ListBottleBroken,
  },
  data() {
    return {
      tab: null,
    };
  },
  created() {
    if (this.$route.query.tab == "add-lost-bottle") {
      this.tab = "tab-1";
    }
    // else if (this.$route.query.tab == "list-bottle-broken") {
    //   this.tab = "tab-2";
    // }
  },
  watch: {
    tab: function (value) {
      if (value == "tab-1") {
        this.$router
          .push({ name: "Bottle-Broken", query: { tab: "customer" } })
          .catch(() => {});
      }
      // else if (value == "tab-2") {
      //   this.$router
      //     .push({ name: "Bottle-Broken", query: { tab: "customer-detail" } })
      //     .catch(() => {});
      // }
    },
  },
};
</script>

<style>
</style>
