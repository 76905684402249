<template>
  <div>
    <v-row>
      <v-col>
        <h3>
          ລາຍການທີ່ເສຍຫາຍທັງໝົດ
          <span class="primary-color">({{ count }})</span>
          <!--
          ຕຸກນ້ຳທີ່ເສຍຫາຍທັງໝົດ
          <span class="primary-color">({{ count }})</span>
          -->
        </h3>
      </v-col>
      <v-col>
        <h3>
          ລວມຕຸກນ້ຳເສຍຫາຍ
          <span class="primary-color">({{ sumTotalBottle }})</span>
          <!--
          ຕຸກນ້ຳທີ່ເສຍຫາຍທັງໝົດ
          <span class="primary-color">({{ count }})</span>
          -->
        </h3>
      </v-col>
      <v-col>
        <h3>
          ລວມເງິນຄ່າເສຍຫາຍ
          <span class="primary-color">
            {{ Intl.NumberFormat().format(sumTotalValue) }}</span
          >
          <!--
          ຕຸກນ້ຳທີ່ເສຍຫາຍທັງໝົດ
          <span class="primary-color">({{ count }})</span>
          -->
        </h3>
      </v-col>
      <!--
      <v-col>
        <v-text-field
          outlined
          dense
          clearable
          label="ຄົ້ນຫາ ດ້ວຍລະຫັດ, ຊື່, ເບີໂທ, ບ້ານ"
          type="text"
          v-model="search"
          @keyup.enter="fetchLostBottle()"
        >
        </v-text-field>
      </v-col>
-->
    </v-row>

    <!--Header-->

    <v-row>
      <v-col cols="4">
        <v-menu
          v-model="start_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="start_date"
              label="ເລີ່ມວັນທີ"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="start_date"
            @input="fetchLostBottle()"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-spacer></v-spacer>
      <v-col cols="4">
        <v-menu
          v-model="end_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="end_date"
              label="ຫາວັນທີ"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="end_date"
            @input="fetchLostBottle()"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="selectedLostPlaceFilter"
          :items="lostplaces"
          label="ບ່ອນເສຍຫາຍ"
          item-text="name"
          item-value="value"
          dense
          outlined
          @input="fetchLostBottle()"
        ></v-select>
      </v-col>
    </v-row>
    <!--
    <v-row justify="end">
      <template>
        <v-btn class="btn-primary" fab small dark @click="OpenModalAdd()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </v-row>
-->
    <v-data-table
      :headers="headers"
      :items="listlostbottles"
      :disable-pagination="true"
      hide-default-footer
      class="elevation-1"
      :loading="TableLoading"
      :disabled="TableLoading"

      loading-text="Loading... Please wait"
    >
      <!--Action -->
      <!--
      <template v-slot:item.customer_code="{ item }">
        <v-chip color="success">{{ item.customer_code }}</v-chip>
      </template>
      -->

      <template v-slot:item.offender="{ item }">
       <div v-if="item.offender">
         <div>{{ item.offender.name }} - {{ item.offender.phone }}</div>
       </div>
      </template>
      <template v-slot:item.lost_place="{ item }">
        <div>
          {{ getstLostPlace(item.lost_place) }}
        </div>
      </template>
      <template v-slot:item.createdDate="{ item }">
        {{ moment(item.createdDate).format("DD-MM-YYYY") }}
        <!-- {{item.created_at}} -->
      </template>
      <template v-slot:item.pay_for_fine="{ item }">
        {{ Intl.NumberFormat().format(item.pay_for_fine) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <!-- <v-icon small class="mr-2" @click="viewInvoice(item)">mdi-eye</v-icon> -->
        <v-icon small class="mr-2" @click="OpenModalEdit(item)">
          mdi-pencil
        </v-icon>
        <!--
        <v-icon small @click="deleteItem(item.id)"> mdi-delete </v-icon>
      </template>-->
      </template>
    </v-data-table>
    <br />
    <Pagination
      v-if="pagination.last_page > 1"
      :pagination="pagination"
      :offset="offset"
      @paginate="fetchLostBottle()"
    ></Pagination>

    <!--Delete Modal-->
    <ModalAdd>
      <template @close="close">
        <v-card>
          <v-card-title>
            <span>ເພີ່ມຕຸກເສຍຫາຍ</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-menu
                      v-model="lost_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="lost_date"
                          label="ເລີ່ມວັນທີ"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="lost_date"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      required
                      v-model="selectedLostPlace"
                      :items="lostplaces"
                      item-text="name"
                      item-value="value"
                      label="ສະຖານທີ່ເສຍຫາຍ *"
                      :rules="lostPlaceRule"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" v-if="selectedLostPlace == 'factory'">
                    <v-autocomplete
                      dense
                      required
                      v-model="selectedOffender"
                      :items="factoryUser"
                      item-text="user_name"
                      item-value="userId"
                      label="ເລືອກ User ໂຮງງານ *"
                      :rules="userRule"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" v-if="selectedLostPlace == 'driver'">
                    <v-autocomplete
                      dense
                      required
                      v-model="editLostBottle.id"
                      :items="trucks"
                      item-text="license_plate"
                      item-value="id"
                      label="ເລືອກລົດ *"
                      :rules="ruleTruck"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" v-if="selectedLostPlace == 'branch'">
                    <v-autocomplete
                      dense
                      required
                      v-model="editLostBottle.id"
                      :items="branches"
                      item-text="name"
                      item-value="id"
                      label="ເລືອກສາຂາ"
                      :rules="ruleBranch"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    v-if="!selectedTruck == '' && selectedLostPlace == 'driver'"
                  >
                    <v-autocomplete
                      dense
                      required
                      v-model="editLostBottle.id"
                      :items="deliveryUser"
                      item-text="name"
                      item-value="id"
                      label="ເລືອກ User ຂົນສົ່ງ *"
                      :rules="userRule"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col
                    cols="12"
                    v-if="
                      !selectedBranch == '' && selectedLostPlace == 'branch'
                    "
                  >
                    <v-autocomplete
                      dense
                      required
                      v-model="editLostBottle.id"
                      :items="branchUser"
                      item-text="name"
                      item-value="id"
                      label="ເລືອກ User ສາຂາ *"
                      :rules="userRule"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" v-if="selectedLostPlace == 'customer'">
                    <v-autocomplete
                      dense
                      required
                      v-model="editLostBottle.id"
                      :items="ຈຸດສັງເກດ"
                      item-text="name"
                      item-value="id"
                      label="ເລືອກເມືອງ *"
                      :rules="ruleDistrict"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" v-if="selectedLostPlace == 'customer'">
                    <v-autocomplete
                      dense
                      required
                      :loading="loadingCustomer"
                      :disabled="loadingCustomer"
                      v-model="editLostBottle.id"
                      item-value="id"
                      :items="customers"
                      :item-text="(item) => item.name + ' - ' + item.village"
                      label="ເລືອກລູກຄ້າ"
                      :rules="ruleCustomer"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      label="ຄ່າເສຍຫາຍ *"
                      required
                      v-model="editLostBottle.pay_for_fine"
                      :rules="payforfineRules"
                      type="number"
                      class="input-number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="ໝາຍເຫດ"
                      v-model="editLostBottle.remark"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="11">
                    <v-text-field
                      label="Barcode ທີ່ຕຸກນ້ຳເສຍຫາຍ*"
                      v-model="barcode"
                      type="number"
                      class="input-number"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.barcode }}
                    </p>
                    <p class="errors">{{ serverMsg }}</p>
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                      class="ml-2 mr-0"
                      fab
                      small
                      dark
                      color="btn-primary"
                      @click="AddBarcode()"
                    >
                      <v-icon dark> mdi-plus </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col col="12">
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr v-for="(item, index) in barcodes" :key="index">
                            <td>
                              {{ item }}
                            </td>
                            <td>
                              <v-icon small @click="RemoveBarcode(index)">
                                mdi-delete
                              </v-icon>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="CloseModalAdd()">
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="loading"
                @click="AddItem()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalAdd>

    <!--Edit Modal-->

    <ModalEdit>
      <template @close="close">
        <v-card>
          <v-card-title>
            <span class="headline">Update ຄ່າເສຍຫາຍ</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="ຄ່າເສຍຫາຍ *"
                      required
                      v-model="editLostBottle.pay_for_fine"
                      :rules="payforfineRules"
                      type="number"
                      class="input-number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="ໝາຍເຫດ"
                      v-model="editLostBottle.remark"
                      :disabled="true"
                    ></v-text-field>
                  </v-col>
                  <v-col col="12">
                    ລາຍລະອຽດ
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr
                            v-for="(item, index) in editLostBottle.bottles"
                            :key="index"
                          >
                            <td>
                              {{ item.code_id }}
                            </td>
                            <!-- <td>
                              <v-icon small @click="RemoveBarcode(index)">
                                mdi-delete
                              </v-icon>
                            </td> -->
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="CloseModalEdit()">
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="loading"
                @click="UpdateItem()"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalEdit>

    <!--Delete Modal-->
    <ModalDelete>
      <template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="loadingView"
            :disabled="loadingView"
            @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
    </ModalDelete>
    <AddBarcode @filter_village="RefreshVillage()" />
    <!-- View Modal -->

    <ModalView>
      <template @close="close">
        <v-card mdi-map-marker-circle>
          <v-card-title>
            <span class="headline">Customer </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row> </v-row>
              <!-- <v-row class="justify-end">
                    <v-col cols="12" class="d-flex justify-end">
                      <p class="invoice justify-end">ທີ່ຢູ່: {{customerDetail.village}}, {{customerDetail.district}}, {{customerDetail.proName}} [{{customerDetail.ຈຸດສັງເກດ}}]</p>
                    </v-col>
                  </v-row> -->
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeModalView()">
                Close
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalView>
  </div>
</template>

<script>
import manage_lostbottle from "../../mixins/lostbottle";
import AddBarcode from "@/views/BottleBroken/addBarcode";
import Pagination from "@/plugins/pagination/pagination";

export default {
  mixins: [manage_lostbottle],
  components: {
    AddBarcode,
    Pagination,
  },
  data() {
    return {};
  },
  methods: {},
  created() {
    // this.fetchLostBottle();
    // this.fetchFactoryUser();
    // this.fetchDriver();
    // this.listDeliveryUser();
    // this.listBranch();
    // this.listBranchUser();
    // this.fetchຈຸດສັງເກດ();
    this.fetchLostBottle();
  },
};
</script>

<style scoped>
/*@import "../../../../../public/scss/main.scss";*/
.customer {
  color: #000;
  font-size: 18px;
}
.invoice {
  font-size: 18px;
}
.primary-color {
  color: #00c1d2;
}
.text-field {
  border-color: #00c1d2;
  padding: 12px 8px 12px 8px;
  width: 100%;
  margin-bottom: 12px;
  font-size: 16px;
}
</style>
