<template>
  <div>
    <ModalAdd2>
      <template @close="close">
        <v-card>
          <v-card-title>
            <span>ເພີ່ມລະຫັດຕຸກທີ່ເສຍຫານ</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Barcode ທີ່ຕຸກນ້ຳເສຍຫາຍ*"
                      required
                      v-model="barcode"
                      type="number"
                      class="input-number"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="CloseAddBarcode()">
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="loading"
                @click="AddItem()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalAdd2>
  </div>
</template>
<script>
import manage_lostbottle from "@/mixins/lostbottle";
export default {
  mixins: [manage_lostbottle],
  data() {
    return {};
  },
  methods: {
    CloseAddBarcode() {
      this.$store.commit("modalAdd2_State", false);
    },

    AddItem() {
      if (this.$refs.form.validate() == true) {
        this.SubmitItem();
      }
    },
    SubmitItem() {
      localStorage.setItem("barcode", JSON.stringify(this.barcode));
      this.CloseAddBarcode();
    },
    getBarcodes() {
      this.barcodelostes = JSON.parse(localStorage.getItem("barcode") || "[]");
      // this.$emit("itemData", this.invoiceDetail);
    },
  },
  created() {
    this.getBarcodes();
  },
};
</script>

<style>
</style>